<div class="mapping-container d-flex flex-column">
    <section class="header-section">
        <div class="heading-container d-flex align-items-center justify-content-between text-align-right-rtl mb-3 px-2">
            <div class="d-flex gap-8 align-items-center">
                <div class="head" translate>{{harmonisationType|titlecase}} Mapping</div>
            </div>
            <i class="icon icon-Close2 cursor-pointer" (click)="onPanelClose()" placement="left"
                ngbTooltip="{{'Close' | translate}}" tooltipClass="info-custom-tooltip"></i>
        </div>
        <div class="d-flex align-items-center justify-content-between sub-header px-3 py-3">
            <span> Map {{harmonisationType|titlecase}}</span>
            <div class="green-banner show" *ngIf="!harmonisedGrid.selectedHarmonised && rawGrid.checkedIds.length">
                {{rawGrid.checkedIds.length}} raw {{harmonisationType | plural:rawGrid.checkedIds.length:harmonisationType==='category'?'ies':'s'}} {{rawGrid.checkedIds.length > 1 ? 'are' : 'is'}} selected, select relevant uniform harmonised {{harmonisationType}} to apply mapping.
            </div>
            <div class="green-banner show" *ngIf="harmonisedGrid.selectedHarmonised && !rawGrid.checkedIds.length">
                Uniform harmonised {{harmonisationType}} selected, please select raw {{harmonisationType}} to map.
            </div>
            <button class="btn super-btn btn-lg" [disabled]="(!rawGrid.checkedIds.length || !harmonisedGrid.selectedHarmonised) || isMappingInProgress"
            (click)="onMapping()">
                <i class="icon icon-Forward"></i>
                <span>{{'Apply Mapping' | translate}}</span>
            </button>
        </div>
    </section>
    <section class="map-body-section d-flex">
        <div class="raw-entity">
            <div class="p-3">
                <div class="grid-name d-flex justify-content-between gap-8">
                    <div class="d-flex align-items-center gap-8">
                        <span class="name">Raw {{harmonisationType | titlecase |
                            plural:2:harmonisationType.includes('category')?'ies':'s'}}</span>
                        <span class="grid-count" *ngIf="rawGrid.totalCount">{{rawGrid.totalCount | numberSuffix  : 2}}</span>
                    </div>
                    <app-search-common [searchObj]="searchRawObj" (onSearch)="onRawSearch($event)"></app-search-common>
                </div>
                <div class="raw-box-wrapper" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                    [scrollWindow]="false" (scrolled)="rawGridCall()">
                    <ng-container *ngIf="rawGrid.rawGridList.length;else loader">
                        <ng-container *ngFor="let item of rawGrid.rawGridList;let i=index">
                            <div class="raw-box d-flex flex-column mb-2" [@fadeAnimation]>
                                <div class="d-flex gap-8">
                                    <label class="checkbox" [for]="'header'+i">
                                        <input type="checkbox" name="checkbox" [checked]="rawGrid.checkedIds.includes(item.id)" [disabled]="variantData?.[item.id]?.isLoading" [id]="'header'+i" (change)="onRawEntityCheckUncheck($event,item)">
                                        <div class="checkbox-section"><i class="icon icon-Check"></i></div>
                                    </label>
                                    <section class="d-flex flex-column">
                                        <span>{{item.attributes.name}}</span>
                                        <ng-container *ngIf="harmonisationType==='product' && item.attributes.variant_count">
                                            <div ngbDropdown class="d-inline-block position-static variant-list" display="dynamic">
                                                <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer" (click)="getVariantList(item.id)"><span class="variant-title p-0">In {{item.attributes.variant_count}} {{item.attributes.variant_count>1?'Variants':'Variant'}}</span>
                                                </span>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="variantDropdownList">
                                                    <div class="font-medium title mb-2">Product variants</div>
                                                    <ng-container *ngIf="!variantData?.[item.id]?.['isLoading'] && variantData?.[item.id]?.['list']?.length; else variantLoading">
                                                        <section class="d-flex flex-column variant-items">
                                                            <div class="checkbox-container more-item">
                                                                <label class="checkbox" [for]="'select_all_'+item.id">
                                                                    <input type="checkbox" name="checkbox" [id]="'select_all_'+item.id" (change)="onVariantCheckUncheck($event,item,null)"
                                                                        [checked]="variantData?.[item.id]?.['list']?.length===variantSelected?.[item.id]?.length" [disabled]="!rawGrid.checkedIds.includes(item.id)">
                                                                    <div class="checkbox-section"><i class="icon icon-Check"></i></div>
                                                                    <span class="fil-text user-select-none font-medium">{{'Select all variants' | translate}}</span>
                                                                </label>
                                                            </div>
                                                            <div class="checkbox-container more-item" *ngFor="let data of variantData?.[item.id]?.['list']">
                                                                <label class="checkbox" [for]="item.id+'_'+data.id">
                                                                    <input type="checkbox" name="checkbox" [id]="item.id+'_'+data.id"
                                                                        [checked]="variantSelected?.[item.id]?.includes(data.id)" [disabled]="!rawGrid.checkedIds.includes(item.id)"
                                                                        (change)="onVariantCheckUncheck($event,item,data)">
                                                                    <div class="checkbox-section"><i class="icon icon-Check"></i></div>
                                                                    <span class="checkbox-data">{{data.name}}</span>
                                                                </label>
                                                            </div>
                                                        </section>
                                                    </ng-container>
                                                    <ng-template #variantLoading>
                                                        <div class="no-data-common">
                                                            <div class="loading-panel">
                                                                <img src="../../../../../assets/images/spinner.svg" alt="">
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </section>
                                </div>
                                <div class="ms-4 d-flex gap-1" *ngIf="['store'].includes(harmonisationType)">
                                    <app-marketplace-store-logo
                                        [storeName]="item.attributes.marketplace"></app-marketplace-store-logo> <span
                                        class="text"></span>{{item.attributes.marketplace}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <ng-template #loader>
                    <div class="no-data-common">
                        <div *ngIf="!rawGrid.isLoading" class="no-data-found" translate>
                            <div class="no-data-text" translate>No data present</div>
                        </div>
                        <div *ngIf="rawGrid.isLoading" class="loading-panel">
                            <img ngSrc="../../../../../assets/images/spinner.svg" alt="" height="200" width="200"/>
                        </div>
                    </div>
                </ng-template>

            </div>

        </div>
        <div class="harmonised-entity">
            <div class="p-3">
                <div class="grid-name d-flex gap-8 justify-content-between">
                    <div class="d-flex align-items-center gap-8">
                        <span class="name">Uniform Harmonised {{harmonisationType | titlecase |
                            plural:2:harmonisationType.includes('category')?'ies':'s'}}</span>
                        <span class="grid-count"
                            *ngIf="!harmonisedGrid.isLoading">{{harmonisedGrid.filteredGridList.length | numberSuffix  : 2}}</span>
                    </div>
                    <ng-select #selectHarmonisedEntity [(ngModel)]="harmonisedGrid.selectedHarmonised" class="select-text-wrap" [notFoundText]="'No Data Found' | translate"
                    [loadingText]="'Loading...' | translate" [items]="harmonisedGrid.harmonisedGridList"
                    [addTag]="onAddNewHarmonisedEntity" [addTagText]="'+ Add New Harmonised '+harmonisationType|titlecase"
                    [placeholder]="'Create or Select a Harmonised '+(harmonisationType | titlecase)| translate" bindLabel="attributes.name"
                     [loading]="harmonisedGrid.isLoading" [closeOnSelect]="true"
                    [clearable]="true" (search)="onHarmonisedEntitySearch($event)" (close)="onHarmonisedSearchClose()" (clear)="onHarmonisedSearchClose()">
                </ng-select>
                </div>
                <div class="d-flex flex-wrap justify-content-between harmonised-raw-wrapper">
                    <ng-container *ngIf="harmonisedGrid.filteredGridList.length;else harmonisedLoader">
                        <ng-container *ngFor="let harmonised of harmonisedGrid.filteredGridList">
                            <div class="raw-box d-flex flex-column mb-2 cursor-pointer" (click)="onHarmonisedEntitySelected(harmonised)" [ngClass]="{'selected-box':harmonisedGrid.selectedHarmonised?.id===harmonised.id}" [@fadeAnimation]>
                                <span class="name">{{harmonised.attributes.name}}</span>
                                <!-- <div translate>
                                    <span class="badgeView"
                                        [ngClass]="harmonised.attributes.mapped_details.length?'badge-dark-blue':'badge-paused'">{{harmonised.attributes.mapped_details.length
                                        | numberSuffix : 2}}</span> &nbsp;Raw {{harmonisationType | titlecase |
                                    plural:harmonised.attributes.mapped_details.length:harmonisationType.includes('category')?'ies':'s'}}
                                </div> -->
                                <div ngbDropdown display="dynamic" class="harmonised-raw-list">
                                    <span class="badgeView cursor-pointer"
                                        [ngClass]="harmonised.attributes.mapped_details.length?'badge-dark-blue':'badge-paused'"
                                        ngbDropdownToggle translate (click)="mappedSelected=[]">{{harmonised.attributes.mapped_details.length
                                        | numberSuffix : 2}}</span> &nbsp;Raw {{harmonisationType |
                                        plural:harmonised.attributes.mapped_details.length:harmonisationType.includes('category')?'ies':'s'}}
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="variantDropdownList"
                                        *ngIf="harmonised.attributes.mapped_details.length">
                                        <div class="font-medium title mb-2 d-flex justify-content-between align-items-center">
                                            <span>Raw {{harmonisationType |
                                            plural:2:harmonisationType.includes('category')?'ies':'s'}}</span>
                                            <button class="btn btn-primary btn-sm"
                                                (click)="openHarmonisedUnmapModal(harmonised.id, mappedSelected, harmonised)"
                                                [disabled]="mappedSelected.length===0">Unmap {{(harmonisationType |
                                                plural:mappedSelected.length:harmonisationType.includes('category')?'ies':'s' )| titlecase}}</button>
                                        </div>
                                        <section class="d-flex flex-column variant-items">
                                            <div class="checkbox-container more-item">
                                                <label class="checkbox" [for]="'select-all-'+harmonised.id">
                                                    <input type="checkbox" name="checkbox" [id]="'select-all-'+harmonised.id"
                                                        [checked]="mappedSelected.length===harmonised.attributes.mapped_details.length"
                                                        (change)="onHarmonisedMappedCheckUncheck($event, null, harmonised.attributes.mapped_details)">
                                                    <div class="checkbox-section"><i class="icon icon-Check"></i></div>
                                                    <span class="fil-text user-select-none font-medium select-all-text">{{('Select all
                                                        '+harmonisationType |
                                                        plural:2:harmonisationType.includes('category')?'ies':'s') | translate}}</span>
                                                </label>
                                            </div>
                                            <div class="checkbox-container more-item"
                                                *ngFor="let mapped of harmonised.attributes.mapped_details">
                                                <label class="checkbox" [for]="harmonised.id+'-'+mapped.id">
                                                    <ng-container>
                                                        <input type="checkbox" name="checkbox" [id]="harmonised.id+'-'+mapped.id"
                                                            [checked]="mappedSelected.includes(mapped.id)"
                                                            (change)="onHarmonisedMappedCheckUncheck($event, mapped, harmonised.attributes.mapped_details)">
                                                        <div class="checkbox-section"><i class="icon icon-Check"></i></div>
                                                    </ng-container>
                                                    <span class="checkbox-data">{{mapped.name}}</span>
                                                </label>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #harmonisedLoader>
                        <div class="no-data-common">
                            <div *ngIf="!harmonisedGrid.isLoading" class="no-data-found" translate>
                                <div class="no-data-text" translate>No data present</div>
                            </div>
                            <div *ngIf="harmonisedGrid.isLoading" class="loading-panel">
                                <img ngSrc="../../../../../assets/images/spinner.svg" alt=""  height="200" width="200"/>
                            </div>
                        </div>
                    </ng-template>
                </div>

            </div>
        </div>
    </section>
</div>