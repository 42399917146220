import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from "@angular/core";
import {
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { HarmonisationService } from "@ds-private-layouts/configuration/harmonisation/harmonisation.service";
import { AccessMatrixService } from "@ds-shared/common-services/utility-services/access-matrix.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { CustomValidationsService } from "@ds-shared/common-services/utility-services/custom-validations.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { NotificationService } from "@ds-shared/common-services/utility-services/notification.service";
import { LOCALFILTER } from "@ds-shared/enums/common.enum";
import { Modules } from "@ds-shared/enums/modules.enum";
import { RolePermissions } from "@ds-shared/models/access-matrix.model";
import { LocalFilters, ModuleType } from "@ds-shared/models/common.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, Subscription, takeUntil } from "rxjs";

@Component({
	selector: "app-harmonization-actions",
	templateUrl: "./harmonization-actions.component.html",
	styleUrls: ["./harmonization-actions.component.scss"]
})
export class HarmonizationActionsComponent implements OnInit, OnDestroy {
	@Input() public moduleType: ModuleType;
	@Input() public actionData: any;
	@Input() public actionType: string;
	@Input() public col;
	@Input() public meta;
	@Input() public pageSize;

	@Output() refreshGrid = new EventEmitter();
	private destroy$: Subject<boolean> = new Subject();
	public clearLastCall: Subscription;
	public permissions: RolePermissions = new RolePermissions();
	public trackers: string = "";
	public productCollection: UntypedFormGroup;
	public productFilters: { [key: string]: LocalFilters } = {
		[LOCALFILTER.PRODUCT_SEGMENT]: {
			label: "Product Segment",
			placeholder: "Product Segment",
			list: [],
			meta: { key: "attributes.name" },
			selected: [],
			searchTerm: "",
			loading: false,
			path: "brands",
			query: [],
			clear: true,
			closeOnSelect: false,
			payloadString: "segment"
		},
		[LOCALFILTER.BRAND]: {
			label: "Harmonised Brand",
			placeholder: "Harmonised Brand",
			list: [],
			meta: { key: "attributes.name" },
			selected: [],
			searchTerm: "",
			loading: false,
			path: "brands",
			query: [],
			clear: true,
			closeOnSelect: false,
			payloadString: "harmonised_brand_id"
		}
	};
	public filters: string[] = Object.keys(this.productFilters);
	constructor(
		public commonFunctions: CommonFunctionsService,
		private modalService: NgbModal,
		private route: Router,
		private accessMatrixService: AccessMatrixService,
		private harmonisationService: HarmonisationService,
		private notificationMessageService: NotificationService,
		private customValidationService: CustomValidationsService,
		private dataTransferService: DataTransferService
	) {
		this.permissions = this.accessMatrixService.getPermissionByRoute();
	}

	ngOnInit(): void {}

	private getDefaultValue(label: string) {
		const forLabel =
			label === "brand" ? "harmonised_brand_name" : "product_segment";
		return this.actionData["attributes"]?.[forLabel];
	}

	private getFilters() {
		this.filters.forEach((item: string) => {
			this.harmonisationService
				.filterharmonisedList(item, this.commonFunctions.currencySelected.id)
				.pipe(takeUntil(this.destroy$))
				.subscribe((res: any) => {
					this.productFilters[item].list = res.data;
				});
		});
	}

	public openModal(content, isEditPopup) {
		this.modalService.open(content, {
			centered: true,
			windowClass: "customModal harmonisedModal",
			keyboard: false,
			backdrop: "static"
		});
		this.productCollection = new UntypedFormGroup({
			brand: new UntypedFormControl(this.getDefaultValue("brand")),
			product_segment: new UntypedFormControl(
				this.getDefaultValue("product_segment")
			),
			new_product_segment: new UntypedFormControl(null, [
				this.validateString.bind(this)
			]),
			harmonisedName: new UntypedFormControl("", [
				Validators.required,
				this.validateString.bind(this)
			])
		});
		this.getFilters();
		this.productCollection
			.get("harmonisedName")
			.patchValue(this.actionData.attributes.name);
		this.trackers = this.actionData.attributes.linked_tracker
			.map((data) => data.name)
			.join(", ");
	}

	private validateString(control: UntypedFormControl): ValidationErrors | null {
		return this.customValidationService.validateInputString(control);
	}
	public updateHarmonisationData() {
		let param = {};
		param["name"] = this.productCollection.get("harmonisedName").value?.trim();
		if (this.productCollection.get("brand").value)
			param["harmonised_brand_id"] =
				typeof this.productCollection.get("brand").value === "string"
					? this.actionData["attributes"]["harmonised_brand_id"]
					: this.productCollection.get("brand").value.id;
		if (this.productCollection.get("product_segment").value)
			param["segment"] =
				typeof this.productCollection.get("product_segment").value === "string"
					? this.productCollection.get("product_segment").value
					: this.productCollection
							.get("product_segment")
							.value?.attributes?.name.trim();
		if (this.productCollection.get("new_product_segment").value)
			param["segment"] = this.productCollection
				.get("new_product_segment")
				.value.trim();
		this.harmonisationService
			.editHarmonisation(this.moduleType.name, this.actionData.id, param)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res) => {
					if (res) {
						this.refreshGrid.emit(true);
						this.notificationMessageService.setMessage(
							res["context_code"],
							res["summary"]
						);
					}
				},
				(err) => {
					this.notificationMessageService.setMessage(
						err.error["context_code"],
						err.error["summary"]
					);
				}
			);
	}

	public delHarmonisation() {
		this.harmonisationService
			.deleteHarmonisation(this.moduleType.name, this.actionData.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res) => {
					if (res) {
						const pageNo = this.commonFunctions.getPageNo(
							this.meta,
							this.pageSize
						);
						this.refreshGrid.emit({
							isRefresh: true,
							isDelete: true,
							pageNo: pageNo
						});
						this.dataTransferService.sendSignalOnHarmonisationCrud(true);
						this.notificationMessageService.setMessage(
							res["context_code"],
							res["summary"]
						);
					}
				},
				(err: any) => {
					this.notificationMessageService.setMessage(
						err.error.context_code,
						err.error.summary
					);
				}
			);
	}

	public onChange(type?: string) {
		if (type === "new") this.productCollection.get("product_segment").reset();
		else this.productCollection.get("new_product_segment").reset();
	}

	onKeyEnter() {
		this.productCollection.get(LOCALFILTER.PRODUCT_SEGMENT).reset();
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
