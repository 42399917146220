import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { RolePermissions } from '@ds-shared/models/access-matrix.model';
import { DocumentCollection } from 'ngx-jsonapi';
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { ColumnModel, InnerGridDetailFormat, MiscellaneousTriggerDetails, ModuleType } from '@ds-shared/models/common.model';
import { QuickCompareComponent } from '../discover/quick-compare/quick-compare.component';
import { Subject, takeUntil } from 'rxjs';
import { DataTransferService } from '@ds-shared/common-services/utility-services/data-transfer.service';
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-ngx-grid-revamp',
  templateUrl: './ngx-grid-revamp.component.html',
  styleUrls: ['./ngx-grid-revamp.component.scss']
})
export class NgxGridRevampComponent implements OnInit, OnDestroy {
	@ViewChild("select") select: NgSelectComponent;
	private onScroll = (event: any) => {
		if (this.select && this.select.isOpen) {
			const isScrollingInScrollHost =
				(event.target.className as string).indexOf("ng-dropdown-panel-items") >
				-1;
			if (isScrollingInScrollHost) {
				return;
			}
			this.select.close();
		}
	};
	@Input() public ngxCols: ColumnModel[] = [];
	@Input() public ngxItems: any;
	@Input() public collection: DocumentCollection;
	@Input() public isPaginationWithoutRoute: boolean;
	@Input() public itemPerPage: number;
	@Input() public loadingData: boolean;
	@Input() public noData: boolean;
	@Input() public moduleType: ModuleType;
	@Input() public column: ColumnModel[];
	@Input() public maxSize: number = 3;
	@Input() public showThreeOptionsForPageSize: boolean = false;
	@Input() public showPopUpPageSize: boolean = false;
	@Input() public gridGoToPage: boolean = true;
	@Input() public customNoDataText = "No data present"; //default text
	@Input() public componentDetail: any;
	@Input() public isTable: boolean = true;
	@Input() public className: string;
	@Input() public goToPlaceholder: string = "Go to page";
	@Input() public pgType: string = "bg";
	@Input() public innerGridDetails: InnerGridDetailFormat;
	@Output() public sortData = new EventEmitter();
	@Output() paginationSize = new EventEmitter();
	@Output() paginationNo = new EventEmitter();
	@Output() navigationTrigger = new EventEmitter();
	@Output() refreshGrid = new EventEmitter();
	// for miscellaneous triggers
	@Output() miscTrigger: EventEmitter<MiscellaneousTriggerDetails> =
		new EventEmitter();
	public currentPage: number = 1;
	public sortActive: {
		colObj: ColumnModel;
		sortKey: string;
		sortDirection: string;
		sameCount: number;
	} = {
		colObj: null,
		sortKey: null,
		sortDirection: "down",
		sameCount: 0
	};
	public selectedPage: number;
	public pageSize: { id: number; value: number }[] = [];
	public selectedPage_size: number = 10;
	public permissions: RolePermissions = new RolePermissions();
	public bulkSelect: boolean = false;
	public itemSelected: Set<number> = new Set();
	public showHeader: boolean = true;
	public goToPage: number = null;
	@ViewChild(QuickCompareComponent)
	quickCompareComponent: QuickCompareComponent;
	@ViewChild("gridWrapperIdentifier") gridWrapperIdentifier: ElementRef;
	@ViewChild("noDataAvailableIdentifier") noDataAvailableIdentifier: ElementRef;
	public leftFilterHeightVal: any;
	public sortHeightVal: any;
	private $destroy: Subject<boolean> = new Subject();
	@Input() bulkActionParams: any = {};
	@Input() isScorecardList: boolean = false;
	@Input() isDefaultScort: boolean = false;
	constructor(
		public commonFunctions: CommonFunctionsService,
		public route: ActivatedRoute,
		private notificationService: NotificationService,
		public router: Router,
		private accessMatrixService: AccessMatrixService,
		public dataTransferService: DataTransferService,
		private localStorageService: LocalStorageService
	) {
		this.permissions = this.accessMatrixService.getPermissionByRoute();
	}

  ngOnInit(): void {
    window.addEventListener("scroll", this.onScroll, true);
    this.pageSize = this.showThreeOptionsForPageSize ? [
      { id: 1, value: 10 },
      { id: 2, value: 20 },
      { id: 3, value: 50 },
    ] : (this.showPopUpPageSize ? [
      { id: 1, value: 5 },
      { id: 2, value: 10 },
      { id: 3, value: 20 },
    ] : [
      { id: 1, value: 5 },
      { id: 2, value: 10 },
      { id: 3, value: 20 },
      { id: 4, value: 50 },
    ]);
    if (this.moduleType.name === 'available_products') {
      this.leftFilterHeight();
      this.sortHeight();
    }
    if (this.isScorecardList && this.localStorageService.get(this.localStorageService.scorecardSort)) {
      const sortCaller = JSON.parse(
        this.commonFunctions.getDecodedData(
          this.localStorageService.get(this.localStorageService.scorecardSort)
        )
      )
      this.sortActive = sortCaller.sortActive;
      this.sortOperation(sortCaller.col);
    }
    else {
      this.defaultSortCall();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.collection && changes.collection.currentValue != undefined) {
        this.currentPage =
          changes.collection.currentValue.meta.current_page || 1;
      }
      if (changes.itemPerPage && changes.itemPerPage.currentValue) {
        this.itemPerPage = changes.itemPerPage.currentValue;
        this.selectedPage_size = this.itemPerPage;
      }
      if (changes.moduleType?.currentValue) {
        if (typeof (this.moduleType?.showHeader) !== 'undefined') this.showHeader = this.moduleType.showHeader;
        if (typeof (this.moduleType?.pagination?.maxSize) !== 'undefined') this.maxSize = this.moduleType?.pagination?.maxSize;
        this.resetSort();
        this.onBulkSelectAction(false);
      }
      if (changes.bulkActionParams?.currentValue) {
        this.onBulkSelectAction(false);
      }
      if (this.isScorecardList) {
        if (this.moduleType.defaultSort?.active) {
          const temp = this.ngxCols.find((item) => item.map === this.moduleType.defaultSort.key);
          if (temp) {
            this.sortActive = {
              colObj: temp,
              sortKey: temp.map.split('.')[temp.map.split('.')?.length - 1],
              sortDirection: this.moduleType.defaultSort.order,
              sameCount: this.moduleType.defaultSort.order === "down" ? 1 : 2
            }
          }
        }
      }
      if (this.isScorecardList || this.isDefaultScort) {
        this.defaultSortCall()
      }
    }

  }

  defaultSortCall() {
    if (this.moduleType.defaultSort?.active) {
      const temp = this.ngxCols.find((item) => item.map === this.moduleType.defaultSort.key);
      if (temp) {
        this.sortActive = {
          colObj: temp,
          sortKey: temp.map.split('.')[temp.map.split('.')?.length - 1],
          sortDirection: this.moduleType.defaultSort.order,
          sameCount: this.moduleType.defaultSort.order === "down" ? 1 : 2
        }
      }
    }

  }

  sortOperation(col: any) {
    if (this.isScorecardList) {
      let tempData = {
        col: col,
        sortActive: this.sortActive
      }
      this.localStorageService.set(this.localStorageService.scorecardSort, this.commonFunctions.getEncodedData(JSON.stringify(tempData)))
    }
    if (col.sort) {
      if (this.sortActive.colObj?.id !== col?.id) {
        this.sortActive.colObj = col;
        this.sortActive.sortKey = col.map.split('.')[col.map.split('.')?.length - 1];
        this.sortActive.sortDirection = 'down';
        this.sortActive.sameCount = 1;
      }
      else {
        this.sortActive.sameCount++;
        if (this.sortActive.sameCount === 3) this.resetSort();
        else this.sortActive.sortDirection = this.sortActive.sortDirection === 'down' ? 'up' : 'down';
      }
      this.sortData.emit(this.sortActive.sameCount > 0 ? [`${this.sortActive.sortDirection === 'up' ? '-' : ''}${this.sortActive.sortKey}`] : []);
    }
  }
  pageChange(page: number) {
    if (this.isPaginationWithoutRoute) {
      this.paginationNo.emit(page);
    } else {
      this.router.navigate(["."], {
        relativeTo: this.route,
        queryParams: { page: page },
        queryParamsHandling: "merge",
      });
    }
    this.selectedPage = 0;
    this.goToPage = null;
  }

  changePageSize(size) {
    if (this.isPaginationWithoutRoute) {
      this.paginationSize.emit(size);
    } else {
      this.router.navigate(["."], {
        relativeTo: this.route,
        queryParams: { page: this.currentPage, size: size },
        skipLocationChange: true,
      });
    }
  }
  onBulkSelectAction(event: boolean) {
    this.bulkSelect = event;
    this.itemSelected.clear();
  }
  trackChange(type: string, item?: any) {
    if (type === 'all') {
      if (!this.checkSelectStatus()) {
        this.ngxItems.data.forEach((item: any) => {
          this.itemSelected.add(item.id);
        })
      }
      else {
        this.ngxItems.data.forEach((item: any) => {
          this.itemSelected.delete(item.id);
        })
      }
    }
    else {
      if (this.itemSelected.has(item.id)) this.itemSelected.delete(item.id);
      else this.itemSelected.add(item.id);
    }
    this.itemSelected = new Set(this.itemSelected);
  }
  checkSelectStatus() {
    let temp = true;
    this.ngxItems?.data?.forEach((item: any) => {
      if (!this.itemSelected?.has(item.id)) {
        temp = false;
      }
    });
    return temp;
  }
  public resetSort(): void {
    this.sortActive = {
      colObj: null,
      sortKey: null,
      sortDirection: 'down',
      sameCount: 0
    }
  }

  public onBulkSuccess(event) {
    this.itemSelected.clear();
    this.bulkSelect = false;
    this.refreshGrid.emit(true);
  }

  public onRefreshGrid(eve) {
    this.refreshGrid.emit(eve);
  }

  public onNavigationTrigger(eventDetails) {
    this.navigationTrigger.emit(eventDetails);
  }
  onQuickClick(product) {
    this.quickCompareComponent.quickComparisonModal(this.route['snapshot'].params?.productId, product?.id);
  }
  openNotification(prod) {
    const noteObj = {
      marketplace: !prod.isStore ? prod?.attributes?.marketplace : prod?.prod?.attributes?.marketplace_name,
      name: !prod.isStore ? prod?.attributes?.name : prod?.prod?.attributes?.store_name,
      id: !prod.isStore ? prod?.id : prod?.prod?.id,
    };
    this.commonFunctions.redirectToNotification(
      (prod.isStore ? 'stores' : 'products'),
      this.commonFunctions.getEncodedData(JSON.stringify(noteObj)),
      this.route
    );
  }
  openDeepCompare(event) {
    this.router.navigate(["scorecard/product/deep-compare"], {
      queryParams: {
        customerProductId: this.route['snapshot'].params?.productId,
        competitorProductId: event.id,
      },
    });
  }
  goToPageFunc() {
    if (this.goToPage) {
      if (this.goToPage > this.collection?.meta?.total_pages) {
        this.notificationService.setMessage(
          1100,
          'The grid has only ' + this.collection?.meta?.total_pages + ' pages'
        );
      }
      else {
        this.pageChange(this.goToPage);
      }
      this.goToPage = null;
    }
  }
  //Discover- Available Products Height (Start)
  leftFilterHeight() {
    this.dataTransferService.leftFilterHeightVal$.pipe(takeUntil(this.$destroy)).subscribe((data) => {
      this.leftFilterHeightVal = data;
      if (this.leftFilterHeightVal) {
        this.setDiscoverGridHeight();
      }
    });
  }

  sortHeight() {
    this.dataTransferService.sortHeightVal$.pipe(takeUntil(this.$destroy)).subscribe((data) => {
      this.sortHeightVal = data;
      this.setDiscoverGridHeight();
    });
  }
  setDiscoverGridHeight() {
    if (this.gridWrapperIdentifier) {
      const gridHeightVar =
        this.gridWrapperIdentifier.nativeElement.firstElementChild.firstElementChild;
      if (this.sortHeightVal == 0 || this.sortHeightVal == "") {
        const letHeightCalc = this.leftFilterHeightVal - 8.75;
        const letHeightCalcString = letHeightCalc + "rem";
        gridHeightVar.style.height = letHeightCalcString;
        if (this.noDataAvailableIdentifier) {
          const noDataHeight = this.noDataAvailableIdentifier.nativeElement;
          noDataHeight.style.height = letHeightCalc + "rem";
        }
      } else {
        const letHeightCalc =
          this.leftFilterHeightVal -
          8.75 -
          Math.floor(this.sortHeightVal / 2.5) * 2.5;
        const letHeightCalcString = letHeightCalc + "rem";
        gridHeightVar.style.height = letHeightCalcString;
        if (this.noDataAvailableIdentifier) {
          const noDataHeight = this.noDataAvailableIdentifier.nativeElement;
          noDataHeight.style.height = letHeightCalc + "rem";
        }
      }
    }
  }
  //Discover- Available Products Height (End)
  trackScrollRows(index: number) {
    return index;
  }
  public rowExpanderToggle(colDetails, itemDetails) {
    itemDetails['isExpanded'] = !itemDetails['isExpanded']
  }

  public onTrendActionTrigger(itemDetails) {
    this.miscTrigger.emit(itemDetails)
  }

  public ngOnDestroy(): void {
    window.removeEventListener("scroll", this.onScroll, true);
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
