import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ComingSoonPopUpComponent } from "../coming-soon-pop-up/coming-soon-pop-up.component";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { NotificationService } from "@ds-shared/common-services/utility-services/notification.service";
import { TranslationService } from "@ds-shared/common-services/utility-services/translation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MixpanelService } from "@ds-common-services/utility-services/mixpanel.service";
import { RolePermissions } from "@ds-shared/models/access-matrix.model";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { AccessMatrix } from "@ds-shared/enums/access-matrix.enum";
import { COL } from "@ds-shared/enums/common.enum";
import html2canvas from "html2canvas";
import { ImageDownloadObj } from "@ds-shared/models/common.model";

@Component({
	selector: "app-common-action-dropdown",
	templateUrl: "./common-action-dropdown.component.html",
	styleUrls: ["./common-action-dropdown.component.scss"]
})
export class CommonActionDropdownComponent implements OnInit {
	@ViewChild("saveWidgetNamePopUp") popUp: ElementRef;
	@ViewChild("downloadAs") downloadAsPopUp: ElementRef;
	public downloadType: string;
	public firstCall: boolean = false;
	@Input() public imageDownloadObj: ImageDownloadObj;
	@Input() public pieData: any;
	@Input() redirectModuleDetail: { name: string; path: string };
	@Input() public path;
	@Input() public filename = "";
	@Input() public columns;
	@Input() public chartType: string;
	@Input() public isPopDownloadActive: boolean;
	@Input() public disableIcon: boolean;
	public name: string;
	public disabledSaveButton: boolean = false;
	@Input() public saveWidgetMetaData: any = {};
	@Input() public showDropdownList = {
		downloadReport: true,
		saveWidget: false
	};
	@Input() public callFromPopUP: boolean = false;
	@Input() public reportType;
	public downloadProgress = {
		disabled: false,
		downloaded: false
	};

	@Input() public saveWidget = {
		disabled: false,
		saved: false
	};

	private _params;
	get params() {
		return this._params;
	}
	@Input() set params(value) {
		this._params = value;
		this.downloadProgress.disabled = false;
	}

	private _optionalParams;
	get optionalParams() {
		return this._optionalParams;
	}
	@Input() set optionalParams(value) {
		this._optionalParams = value;
		this.downloadProgress.disabled = false;
	}

	@Input() public withoutFilterPayload = ["sort", "type"];
	public permissions: RolePermissions = new RolePermissions();
	@Input() spliceFilter;
	constructor(
		private router: Router,
		public commonFunctions: CommonFunctionsService,
		private globalService: GlobalService,
		private ngbModal: NgbModal,
		public notificationService: NotificationService,
		public translationService: TranslationService,
		public modalService: NgbModal,
		private mixpanelService: MixpanelService,
		private accessMatrixService: AccessMatrixService
	) {
		this.permissions = this.accessMatrixService.getPermissionByRoute();
	}

	ngOnInit(): void {
		if (this.showDropdownList.saveWidget) {
			if (this._params.x_axis)
				this.name = `${this.commonFunctions.capitalizeWithUnderscorandSpace(
					this._params.x_axis
				)} vs. ${this.commonFunctions.capitalizeWithUnderscorandSpace(
					this._params.y_axis
				)}`;
			else {
				const parts = [];
				if (this.params.displaying?.length > 0) {
					const displayingName =
						this.params.displaying[0].name === "marketplace"
							? "retail channel"
							: this.params.displaying[0].name;
					parts.push(
						this.commonFunctions.capitalizeFirstLetter(displayingName)
					);
				}
				this.params?.measured_by?.forEach((measure) => {
					const measureString = `${this.commonFunctions.capitalizeWithUnderscorandSpace(
						measure.name
					)}(${measure.aggregation})`;
					parts.push(measureString);
				});
				this.name = parts.join(" vs ");
			}
		}
	}

	navigateToModule(path) {
		this.router.navigate([]).then((result) => {
			window.open(path, "_blank");
		});
	}

	getEndPoint() {
		let paramPath = "";
		if (!this.params) return null;
		for (const [key, value] of Object.entries(this.params)) {
			if (this.withoutFilterPayload.indexOf(key) === -1) {
				if (Array.isArray(value)) {
					paramPath =
						paramPath +
						`${value
							.map((item, i) => `filter[${key}][${i}]=${item}`)
							.join("&")}&`;
				} else paramPath = paramPath + `filter[${key}]=${value}&`;
			}
		}
		return paramPath;
	}

	public onDownloadReport(viewType?: string) {
		if (
			(!this.params && !this.path && this.filename === "") ||
			this.isPopDownloadActive
		) {
			return this.notificationService.setMessage(
				1200,
				this.commonFunctions.getSpecificTranslation(
					"Download is currently not available."
				)
			);
		}
		this.downloadProgress.disabled = true;
		this.notificationService.setMessage(
			1200,
			this.commonFunctions.getSpecificTranslation(
				"Your download will begin shortly."
			)
		);
		// Please dont break it into multiple line
		const pieValue = this.pieData?.reduce((accumulator, item, i) => {
			return (accumulator += `&filter[included_id][${i}]=${item.id}`);
		}, "");
		const endpoint = `${this.path}?${this.getEndPoint()?.substring(
			0,
			this.getEndPoint().length - 1
		)}${
			this.columns?.length
				? this.commonFunctions.getColumnList(this.columns, "columns", "map")
				: ""
		}${this.params?.sort ? `&sort=${this.params.sort}` : ""}${
			this.params?.type && !this.callFromPopUP
				? `&type=${this.params.type}`
				: ""
		}${viewType ? `&filter[view]=${viewType.toLocaleLowerCase()}` : ""}${
			this.optionalParams ? this.optionalParams : ""
		}${this.spliceFilter ? this.spliceFilter : ""}${pieValue ? pieValue : ""}`;
		this.globalService.getExportApi(endpoint).subscribe(
			(res: Blob) => {
				this.notificationService.clearMessage();
				this.mixpanelService.track("File Downloaded - " + this.filename, {});
				this.notificationService.setMessage(
					1000,
					this.commonFunctions.getSpecificTranslation(
						"File downloaded successfully."
					)
				);
				const url = window.URL.createObjectURL(res);
				let a = document.createElement("a");
				document.body.appendChild(a);
				a.setAttribute("style", "display: none");
				a.href = url;
				a.download = "ADS_" + this.filename + ".xlsx";
				a.click();
				window.URL.revokeObjectURL(url);
				a.remove();
				this.downloadProgress.disabled = false;
			},
			(err: any) => {
				this.notificationService.setMessage(
					1100,
					this.commonFunctions.getSpecificTranslation(
						"Something went wrong, please try again."
					)
				);
				this.downloadProgress.disabled = false;
			}
		);
	}

	openModal() {
		//this.ngbModal.open(ComingSoonPopUpComponent, { centered: true, windowClass: "customModal" });
		this.modalService.open(this.popUp, {
			centered: true,
			windowClass: "popUpModal",
			keyboard: false,
			backdrop: "static"
		});
	}

	openDownloadAsPopup() {
		this.downloadType = "";
		this.modalService.open(this.downloadAsPopUp, {
			centered: true,
			windowClass: "popUpModal download customModal",
			keyboard: false,
			backdrop: "static"
		});
	}

	onSaveWidget() {
		this.saveWidget.disabled = true;
		this.notificationService.setMessage(
			1200,
			this.commonFunctions.getSpecificTranslation("Saving process has started")
		);
		const payloads = {
			type: "chart",
			name: this.name,
			path: "reporting/reporting-build",
			payload: this._params,
			meta: this.saveWidgetMetaData
		};
		this.globalService.callSaveWidgetAPI(payloads).subscribe(
			(res: Blob) => {
				this.saveWidget.disabled = false;
				this.notificationService.clearMessage();
				this.notificationService.setMessage(
					1000,
					this.commonFunctions.getSpecificTranslation(
						"Widget Saved Successfully"
					)
				);
			},
			(err: any) => {
				this.saveWidget.disabled = false;
				this.notificationService.setMessage(
					1100,
					this.commonFunctions.getSpecificTranslation("Widget already exists.")
				);
				this.saveWidget.disabled = false;
			}
		);
	}
	onNameChange(event) {
		this.disabledSaveButton = !event.target.value;
		this.name = event.target.value;
	}

	onChange(e) {
		this.downloadType = e.target.value;
	}

	onSelectDownloadType() {
		if (!this.firstCall) this.firstCall = true;
		if (this.downloadType === "excel") this.onDownloadReport();
		else if (this.downloadType === "image") this.onImageDownload();
		this.downloadType = "";
	}

	public onImageDownload() {
		if (this.firstCall) {
			this.notificationService.setMessage(
				1200,
				this.commonFunctions.getSpecificTranslation(
					"Your download will begin shortly."
				)
			);
			let newElement = document.createElement("div");
			newElement.classList.add("fauxEle");
			const textElement = document.createElement("div");
			textElement.innerHTML = `<div class='text-capitalize'>${this.imageDownloadObj?.graphName}</div>`;
			newElement.appendChild(textElement);
			if (this.imageDownloadObj.mainDiv) {
				const element = document.getElementById(this.imageDownloadObj.mainDiv);
				newElement.appendChild(element.cloneNode(true));
			}

			//Check whether we have legend or not
			if (this.imageDownloadObj.legendDiv) {
				const hiddenLegend = document.getElementById(
					this.imageDownloadObj.legendDiv
				);
				hiddenLegend?.classList.add(
					this.imageDownloadObj?.style?.legend?.display
						? this.imageDownloadObj?.style?.legend?.display
						: "d-flex"
				);
				newElement.appendChild(hiddenLegend.cloneNode(true));
				newElement
					.querySelector(`#${this.imageDownloadObj.legendDiv}`)
					.classList.remove("d-none");
			}
			newElement.style.display = "flex";
			newElement.style.flexWrap = "wrap";
			newElement.style.flexDirection = "column";
			newElement.style.justifyContent = "center"; // Horizontal alignment
			newElement.style.alignItems = this.imageDownloadObj?.style
				?.newEleAlignment
				? this.imageDownloadObj?.style?.newEleAlignment
				: "center";
			newElement.style.gap = "1.5rem";
			newElement.style.marginBottom = "1.5rem";
			newElement.style.padding = "1rem";
			newElement.style.height =
				this.imageDownloadObj.style?.newEleHeight || "40rem";
			newElement.style.width =
				this.imageDownloadObj.style?.newEleWidth || "80rem";

			document.body.appendChild(newElement);

			// Use html2canvas to capture the element
			html2canvas(newElement).then((canvas) => {
				const imgData = canvas.toDataURL("image/png");

				const a = document.createElement("a");
				a.href = imgData;
				a.download = `${this.commonFunctions.capitalizeFirstLetter(
					this.imageDownloadObj?.graphName
				)}.png`;
				a.click();
				document.body.querySelector(".fauxEle").remove();
			});
			setTimeout(() => {
				this.notificationService.setMessage(
					1000,
					this.commonFunctions.getSpecificTranslation(
						"Image Downloaded Successfully."
					)
				);
			}, 1000);
		}
		this.firstCall = false;
	}
}
