<div ngbDropdown class="d-inline-block position-static" display="dynamic">
    <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"><span><i class="icon icon-Options"></i></span></span>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="grid-action-dropdown">
       <button [disabled]="!permissions.write_access" class="dropdown-item" (click)="openModal(editHarmonisation,true)"><i class="icon  icon-Edit-alt"></i><span translate>Update Harmonisation</span></button>
       <button [disabled]="!permissions.write_access" class="dropdown-item" (click)="openModal(deleteHarmonisation,false)"><i class="icon  icon-Trash"></i><span translate>Delete Harmonisation</span></button>
    </div>
 </div>
 <ng-template #deleteHarmonisation let-modal>
    <div class="modal-header">
       <h4 class="modal-title" translate>Delete Harmonisation</h4>
    </div>
    <div class="modal-body text-center d-flex justify-content-center align-items-center flex-column">
       <i class="icon  icon-Trash mb-2" ngbAutofocus></i>
       <p translate>{{actionData.attributes.mapped_count}} raw {{moduleType.name | plural:actionData.attributes.mapped_count:moduleType.name==='category'?'ies':'s'}} {{actionData.attributes.mapped_count>1?'are':'is'}} mapped to <b>{{actionData.attributes.name}}</b>. <span *ngIf="trackers.length">Also <b>{{actionData.attributes.name}}</b> is linked to <b>{{trackers}}</b>
    {{actionData.attributes.linked_tracker.length>1?"trackers.":"tracker."}}</span> <span translate> Are you sure you want to delete <b>{{actionData.attributes.name}}</b> ?</span></p>
    </div>
    <div class="modal-footer">
       <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')" translate>Cancel</button>
       <button type="button" class="btn btn-primary btn-lg" (click)="[delHarmonisation(),modal.close('Close click')]" translate>Yes,
          Delete</button>
    </div>
 </ng-template>

 <ng-template #editHarmonisation let-modal>
    <div class="modal-header">
        <h4 class="modal-title" translate>Update Harmonised {{this.moduleType.name | titlecase}}</h4>
        <i class="icon icon-Close2 cursor-pointer" (click)="modal.dismiss('Cross click')">
        </i>
    </div>
    <form [formGroup]="productCollection" class="text-align-right-rtl mt-3" (ngSubmit)="[updateHarmonisationData(),productCollection.get('harmonisedName').reset(),modal.close('Close click')]"  (keydown.enter)="$event.preventDefault()">
        <div class="modal-body">
            <div class="input-container w-100 mb-3">
                <label class="mb-2" for="harmonisedName">Harmonised {{moduleType.name  |titlecase}} Name <sup>*</sup></label>
                    <input type="text" formControlName="harmonisedName" name="harmonisedName" placeholder="Enter harmonised {{moduleType.name}} name"
                    id="harmonisedName" class="w-100" [ngClass]="{ error: productCollection.controls['harmonisedName'].invalid && (productCollection.controls['harmonisedName'].dirty || productCollection.controls['harmonisedName'].touched) }">
                <div class="errorLable" *ngIf="productCollection.controls['harmonisedName'].invalid && (productCollection.controls['harmonisedName'].dirty || productCollection.controls['harmonisedName'].touched)">
                    <span *ngIf="productCollection.controls['harmonisedName'].errors.required" translate>Please enter harmonised {{moduleType.name}} name</span>
                    <span *ngIf="productCollection.controls['harmonisedName'].errors.fieldMessage" translate>{{productCollection.controls['harmonisedName'].errors.fieldMessage}}</span>
                </div>
            </div>
            <div *ngIf="this.moduleType.name === 'product'" class="harmonisation-filter mb-3">
                <ng-container *ngFor="let filter of filters">
                    <div class="mb-3">
                        <label class="mb-2" translate>{{productFilters[filter].label}}</label>
                        <div class="d-flex gap-8 w-100">
                            <ng-select 
                                class="select-text-wrap" [notFoundText]="'No Data Found' | translate"
                                [loadingText]="'Loading...' | translate" [items]="productFilters[filter].list"
                                [placeholder]="('Select ' + productFilters[filter].placeholder) | translate" bindLabel="attributes.name"  formControlName="{{filter}}" [loading]="productFilters[filter].loading" [closeOnSelect]="productFilters[filter].closeOnSelect" [clearable]="productFilters[filter].clear" (change)="onChange()"> 
                            </ng-select>
                            <div *ngIf="filter === 'product_segment'" class="d-flex gap-8 align-items-center w-50">
                                <span>or</span>
                                <div class="input-container w-100">
                                    <input *ngIf="filter === 'product_segment'" formControlName="new_product_segment" type="text" (keyup)="onKeyEnter()" name="new_product_segment" placeholder="Add new product segment" (change)="onChange('new')">
                                    <div  class="errorLable">
                                        <span *ngIf="productCollection.controls['new_product_segment'].invalid && productCollection.controls['new_product_segment'].errors.fieldMessage" translate>{{productCollection.controls['new_product_segment'].errors.fieldMessage}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')"
                translate>Cancel</button>
            <button type="submit" class="btn btn-primary btn-lg"
                [disabled]="productCollection.controls['harmonisedName'].invalid || productCollection.controls['new_product_segment'].invalid"
                translate>Update {{moduleType.name  |
                titlecase}}</button>
        </div>
    </form>
</ng-template>