<!-- <ng-template #content let-modal> -->
    <div class="modal-header">
        <h4 class="modal-title" translate>Edit Store Segment</h4>
    </div>
    <div class="modal-body">
        <p translate ngbAutofocus><span translate>Add <span class="store-name on-hover-action cursor-pointer" (click)="commonFunctions?.redirectToScorecardInfo(data?.id,'store')">{{data?.name}}</span> as ?</span></p>
        <div class="segment-container d-flex">
            <div class="segment" *ngFor="let segment of segments" (click)="activeSegment=segment" [ngClass]="{'active':activeSegment===segment}">{{segmentMap[segment]}}</div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-lg" (click)="activeModal.close(false)"
            translate>Cancel</button>
        <button type="button" class="btn btn-primary btn-lg" [disabled]="this.activeSegment===this.data?.segment" (click)="[updateSegment()]"
            translate>Yes</button>
    </div>
<!-- </ng-template> -->